import React from 'react';
import { Svg } from './Svg';

export const ElectricMotorIcon = ({
  primaryColor = '#035a94',
  secondaryColor = '#388dc5',
  ...props
}) => {
  return (
    <Svg optimizationId='iconElectricMotor' viewBox='0 0 512 512' {...props}>
      <path
        fill={secondaryColor}
        d='m248 104a24 24 0 1 0 24 24 24.02718 24.02718 0 0 0 -24-24zm0 32a8 8 0 1 1 8-8 8.00917 8.00917 0 0 1 -8 8z'
      />
      <path
        fill={secondaryColor}
        d='m312 104a24 24 0 1 0 24 24 24.02718 24.02718 0 0 0 -24-24zm0 32a8 8 0 1 1 8-8 8.00917 8.00917 0 0 1 -8 8z'
      />
      <path
        fill={primaryColor}
        d='m456 200h-80v-16h56a8.00008 8.00008 0 0 0 8-8v-112a24.0275 24.0275 0 0 0 -24-24h-224a24.0275 24.0275 0 0 0 -24 24v104h-24a8.00008 8.00008 0 0 0 -8 8v24h-24a8.00008 8.00008 0 0 0 -8 8v8h-24a8.00008 8.00008 0 0 0 -8 8v64h-48a8.00008 8.00008 0 0 0 -8 8v32a8.00008 8.00008 0 0 0 8 8h48v48a8.00008 8.00008 0 0 0 8 8h24v8a8.00008 8.00008 0 0 0 8 8h24v24a8.00008 8.00008 0 0 0 8 8h32a8.00008 8.00008 0 0 0 8-8v-24h48v16h-24a8.00008 8.00008 0 0 0 -8 8v32a8.00008 8.00008 0 0 0 8 8h184a8.00008 8.00008 0 0 0 8-8v-32a8.00008 8.00008 0 0 0 -8-8h-24v-16h88a40.04552 40.04552 0 0 0 40-40v-128a40.04552 40.04552 0 0 0 -40-40zm-424 120v-16h40v16zm328-120h-25.37616a23.94119 23.94119 0 0 0 0-16h25.37616zm-176-48h16v16h-16zm0 32h25.37616a23.94119 23.94119 0 0 0 0 16h-25.37616zm40 8a8 8 0 1 1 8 8 8.00917 8.00917 0 0 1 -8-8zm30.62384-8h34.75232a23.94119 23.94119 0 0 0 0 16h-34.75232a23.94119 23.94119 0 0 0 0-16zm49.37616 8a8 8 0 1 1 8 8 8.00917 8.00917 0 0 1 -8-8zm88-48v24h-176v-24a8.00008 8.00008 0 0 0 -8-8h-24v-48h240v48h-24a8.00008 8.00008 0 0 0 -8 8zm16 24v-16h16v16zm-216-112h224a8.00917 8.00917 0 0 1 8 8v8h-240v-8a8.00917 8.00917 0 0 1 8-8zm-40 128h16v32h-16zm-64 48h16v144h-16zm32-16h16v176h-16zm48 208h-16v-32h16zm216 16v16h-168v-16h24a8.00008 8.00008 0 0 0 8-8v-56h104v56a8.00008 8.00008 0 0 0 8 8zm-24-80h-120a8.00008 8.00008 0 0 0 -8 8v24h-48v-8a8.00008 8.00008 0 0 0 -8-8h-24v-144h24a8.00008 8.00008 0 0 0 8-8v-8h200v16h-200v16h200v16h-200v16h200v16h-200v16h200v16h-200v16h200v48h-16v-24a8.00008 8.00008 0 0 0 -8-8zm120 8a24.0275 24.0275 0 0 1 -24 24h-56v-176h56a24.0275 24.0275 0 0 1 24 24z'
      />
      <circle fill={secondaryColor} cx='424' cy='256' r='8' />
      <path
        fill={secondaryColor}
        d='m456 280h-32a8.00008 8.00008 0 0 0 -8 8v48a8.00008 8.00008 0 0 0 8 8h32a8.00008 8.00008 0 0 0 8-8v-48a8.00008 8.00008 0 0 0 -8-8zm-8 48h-16v-32h16z'
      />
      <path fill={secondaryColor} d='m184 360h32v16h-32z' />
    </Svg>
  );
};
ElectricMotorIcon.displayName = 'ElectricMotorIcon';

export default ElectricMotorIcon;
