import React from 'react';
import { Svg } from '../../../Icons/Svg';

export const LeroySomer = ({ primaryColor = '#1F8471', ...props }) => {
  return (
    <Svg
      optimizationId='iconLeroySomer'
      viewBox='0 0 880.000000 330.000000'
      {...props}
    >
      <g
        transform='translate(0.000000,330.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
        {...props}
      >
        <path
          d='M6180 2874 c-173 -47 -323 -170 -398 -324 -55 -113 -72 -183 -72
-305 0 -121 17 -192 69 -300 49 -100 120 -179 216 -243 116 -78 183 -97 345
-97 127 0 139 2 212 31 182 74 319 220 380 404 32 99 37 273 9 374 -59 215
-201 372 -402 443 -93 34 -270 42 -359 17z m345 -208 c182 -90 288 -291 257
-489 -24 -154 -121 -287 -262 -355 -73 -36 -78 -37 -186 -37 -107 0 -112 1
-187 38 -196 97 -300 308 -256 519 27 128 124 260 232 315 90 46 125 53 229
49 89 -3 105 -6 173 -40z'
        />
        <path
          d='M6907 2843 c-2 -4 56 -154 130 -332 l134 -323 -61 -257 c-33 -141
-63 -266 -67 -278 -5 -23 -4 -23 91 -23 53 0 96 1 96 3 0 2 29 127 65 277 l66
274 214 323 c118 178 215 328 215 333 0 6 -44 10 -105 10 l-105 0 -146 -221
c-80 -121 -148 -218 -152 -215 -3 4 -45 102 -92 219 l-87 212 -95 3 c-53 1
-98 -1 -101 -5z'
        />
        <path
          d='M8085 2832 c-79 -38 -115 -96 -115 -186 0 -112 92 -193 210 -184 72
5 122 40 158 109 44 83 13 191 -68 245 -52 35 -131 41 -185 16z m138 -26 c18
-7 47 -29 64 -49 93 -106 17 -267 -127 -267 -91 0 -160 72 -160 167 0 114 119
193 223 149z'
        />
        <path
          d='M8080 2650 c0 -91 3 -110 15 -110 11 0 15 12 15 45 0 41 2 45 25 45
17 0 33 -12 55 -45 19 -28 39 -45 51 -45 18 0 18 3 -11 44 -34 51 -36 56 -15
56 23 0 47 44 39 74 -9 36 -32 46 -108 46 l-66 0 0 -110z m139 62 c15 -29 -3
-44 -58 -50 l-51 -5 0 37 0 36 50 0 c37 0 52 -4 59 -18z'
        />
        <path
          d='M3845 2828 c-4 -13 -158 -670 -180 -768 -7 -30 -31 -133 -54 -229
-22 -95 -41 -179 -41 -187 0 -12 41 -14 258 -12 l257 3 17 65 c9 36 17 77 17
93 l1 27 -158 0 -158 0 47 203 c25 111 77 333 113 492 37 160 70 298 72 308 5
15 -4 17 -91 17 -68 0 -97 -4 -100 -12z'
        />
        <path
          d='M4456 2832 c-4 -7 -219 -908 -281 -1180 l-5 -22 297 2 297 3 18 80
c9 44 18 86 18 93 0 9 -46 12 -195 12 -119 0 -195 4 -195 10 0 9 62 273 81
343 l10 37 199 0 198 0 16 69 c9 39 16 75 16 80 0 7 -65 11 -195 11 -107 0
-195 3 -195 8 0 7 44 190 61 255 l10 37 199 0 198 0 10 48 c6 26 14 64 17 85
l7 37 -290 0 c-160 0 -293 -4 -296 -8z'
        />
        <path
          d='M5125 2828 c-4 -12 -115 -483 -232 -981 -25 -109 -44 -199 -42 -201
2 -2 42 -6 88 -7 66 -3 86 0 92 11 5 8 34 122 65 253 l57 237 31 0 c31 0 35
-6 166 -242 74 -132 141 -248 149 -256 11 -11 33 -12 105 -7 50 4 93 9 95 11
2 2 -56 114 -128 249 -72 135 -131 250 -131 254 0 5 20 16 44 25 56 22 149
118 181 189 69 149 52 309 -40 402 -69 68 -95 75 -308 75 -141 0 -189 -3 -192
-12z m361 -192 c34 -34 39 -53 34 -131 -2 -39 -35 -113 -63 -144 -38 -41 -94
-61 -179 -61 -56 0 -78 4 -78 13 0 6 18 90 41 185 l41 172 85 0 c83 0 86 -1
119 -34z'
        />
        <path
          fill={primaryColor}
          d='M985 2713 c-15 -65 -47 -199 -70 -298 -24 -99 -64 -270 -90 -380 -26
-110 -63 -265 -81 -345 -19 -80 -41 -172 -49 -205 -8 -33 -28 -118 -45 -190
-17 -71 -37 -157 -45 -190 -22 -94 -85 -361 -125 -530 -11 -44 -29 -120 -40
-170 -11 -49 -23 -98 -26 -107 -5 -17 62 -18 1310 -18 l1314 0 7 32 c7 32 62
266 125 528 16 69 30 132 30 141 0 8 -26 57 -59 109 -32 52 -121 196 -198 320
-77 124 -205 331 -286 462 -81 130 -147 239 -147 242 0 3 216 6 480 6 l479 0
10 38 c6 20 24 100 42 177 17 77 47 210 66 295 19 85 38 165 40 178 l5 22
-876 0 -876 0 -6 -27 c-4 -16 -40 -161 -82 -323 -41 -162 -79 -314 -84 -337
-9 -42 -9 -43 98 -215 59 -95 193 -310 297 -478 104 -168 211 -340 238 -382
27 -42 49 -79 49 -82 0 -4 -245 -6 -545 -6 -443 0 -545 2 -545 13 0 7 13 69
29 138 65 279 84 361 117 499 36 154 67 287 109 465 14 61 37 155 50 210 13
55 33 141 45 190 11 50 27 117 35 150 8 33 19 81 24 106 6 25 13 53 17 62 6
16 -22 17 -353 17 l-360 0 -28 -117z'
        />
        <path
          d='M4805 1560 c-217 -44 -406 -221 -475 -445 -107 -350 101 -730 450
-820 92 -24 261 -17 350 14 365 127 536 559 360 909 -87 172 -234 291 -415
337 -62 16 -202 18 -270 5z m240 -186 c202 -52 335 -232 335 -452 0 -188 -99
-345 -268 -425 -63 -30 -73 -32 -182 -32 -109 0 -119 2 -183 32 -82 39 -173
126 -212 204 -123 241 -33 525 202 643 96 48 202 58 308 30z'
        />
        <path
          d='M6446 1130 c-148 -243 -270 -439 -272 -438 -1 2 -71 192 -154 423
-83 231 -153 427 -156 435 -7 17 1 39 -184 -535 -204 -636 -220 -685 -220
-690 0 -3 38 -5 84 -5 l85 0 111 343 c62 188 115 345 118 349 4 4 51 -118 105
-270 153 -431 161 -452 168 -452 3 0 107 164 229 365 122 201 224 364 226 362
2 -2 15 -138 29 -303 13 -164 27 -320 31 -347 l6 -47 89 0 89 0 0 28 c0 28
-17 223 -70 830 -16 189 -30 355 -30 368 0 13 -3 24 -7 24 -5 0 -129 -198
-277 -440z'
        />
        <path
          d='M3990 1546 c-80 -18 -129 -44 -186 -101 -69 -69 -97 -142 -98 -251 0
-121 34 -185 161 -301 126 -115 153 -157 153 -238 0 -236 -307 -298 -415 -84
-14 26 -25 55 -25 64 0 8 -4 15 -9 15 -13 0 -128 -69 -139 -83 -17 -21 26
-113 79 -172 185 -201 523 -148 645 101 26 54 29 68 29 164 0 99 -2 110 -32
171 -33 66 -97 135 -196 209 -81 61 -109 158 -68 238 67 130 227 148 319 36
18 -21 32 -44 32 -51 0 -7 6 -13 13 -13 16 0 137 78 137 89 0 16 -62 101 -92
126 -47 40 -117 72 -183 84 -32 6 -61 11 -64 10 -3 0 -31 -6 -61 -13z'
        />
        <path
          d='M7120 1398 c-17 -73 -51 -216 -75 -318 -24 -102 -73 -310 -109 -463
-36 -153 -66 -282 -66 -287 0 -6 113 -10 294 -10 l294 0 21 91 c12 50 21 92
21 95 0 2 -90 4 -200 4 l-200 0 7 28 c4 15 25 101 47 192 l41 165 201 3 200 2
12 58 c7 31 15 67 18 80 l6 22 -196 0 c-150 0 -196 3 -196 13 0 6 14 74 32
149 l33 138 200 2 200 3 11 50 c6 28 14 65 17 83 l7 32 -294 0 -294 0 -32
-132z'
        />
        <path
          d='M7811 1493 c-12 -47 -52 -213 -179 -753 l-98 -415 90 -3 c67 -2 92 1
96 10 2 7 32 121 65 253 59 234 61 240 86 243 34 4 35 2 189 -276 68 -123 128
-226 135 -228 6 -3 55 -3 107 -2 l96 3 -134 250 c-73 138 -134 254 -134 258 0
4 17 15 38 23 53 22 148 119 182 185 51 99 61 238 25 325 -19 45 -82 112 -129
137 -37 20 -58 22 -233 25 l-192 4 -10 -39z m339 -148 c16 -9 37 -30 46 -47
20 -39 21 -122 1 -170 -18 -44 -72 -105 -108 -124 -30 -15 -199 -20 -199 -6 0
5 18 85 40 178 22 93 40 172 40 177 0 14 150 8 180 -8z'
        />
      </g>
    </Svg>
  );
};
LeroySomer.displayName = 'LeroySomer';

export default LeroySomer;
