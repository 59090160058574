import React from 'react'
import { Svg } from './Svg'

export const PhoneNumberIcon = ({
  primaryColor = '#035a94',
  secondaryColor = '#388dc5',
  ...props
}) => {
  return (
    <Svg optimizationId="iconPhoneNumber" viewBox="0 0 33.83 50.03" {...props}>
      <path
        fill={primaryColor}
        d="M32.57 40l-9.23-10.52a4.18 4.18 0 0 0-4.14-1.32l-.76.18a1.73 1.73 0 0 1-2-1 19.94 19.94 0 0 1-1.31-4.51 1.31 1.31 0 0 1 1-1.47A4.17 4.17 0 0 0 19 19a4.25 4.25 0 0 0 .33-2l-.95-12.3A5.05 5.05 0 0 0 11.77.24L5 2.84A6.91 6.91 0 0 0 .26 8.73C-1.2 22.21 3.58 37.37 13.78 47.48a2.29 2.29 0 0 0 3.66-.68l.06-.11a2.31 2.31 0 0 0-.5-2.63C8.1 35.19 3.91 22 4.84 10.21A3.66 3.66 0 0 1 7.4 7L12 5.11a1.36 1.36 0 0 1 1.75 1.19l.72 9.25a1.59 1.59 0 0 1-1.21 1.67l-1.56.38a2.43 2.43 0 0 0-1.82 2.71c.83 5.25 2 8.88 4.87 13.25a2.41 2.41 0 0 0 2.89.89l2.11-.85a1.58 1.58 0 0 1 1.8.46L28.23 42a1.35 1.35 0 0 1-.57 2.15l-3.74 1.32a2.36 2.36 0 0 0-1.35 3.25 2.35 2.35 0 0 0 2.93 1.2l5-1.8A5 5 0 0 0 32.57 40z"
      />
      <path
        fill={secondaryColor}
        d="M22.52 3.35a2.47 2.47 0 0 0 1.82 2.2 5 5 0 0 1 .73 9.45 2.48 2.48 0 0 0-1.46 2.47 2.52 2.52 0 0 0 3.56 2.07A10 10 0 0 0 25.72.75a2.51 2.51 0 0 0-3.2 2.6z"
      />
    </Svg>
  )
}
PhoneNumberIcon.displayName = 'PhoneNumberIcon'

export default PhoneNumberIcon
