import React from 'react';
import { Svg } from '../../../Icons/Svg';

export const SewUsocome = ({ primaryColor = '#BB0707', ...props }) => {
  return (
    <Svg optimizationId='iconSewUsocome' viewBox='0 0 250 123' {...props}>
      <g
        transform='translate(0.000000,123.000000) scale(0.100000,-0.100000)'
        fill={primaryColor}
        stroke='none'
      >
        <path
          d='M272 1214 c-68 -18 -85 -28 -139 -77 -57 -52 -84 -106 -90 -177 -13
-137 72 -228 237 -255 62 -10 80 -22 80 -50 0 -58 -89 -61 -194 -6 l-75 40
-43 -112 c-23 -62 -43 -114 -43 -117 1 -3 45 -21 100 -40 90 -31 111 -34 210
-35 105 0 114 2 177 33 44 22 80 48 104 77 80 98 86 247 12 331 -38 43 -66 58
-148 79 -124 31 -147 48 -110 85 26 26 69 25 153 -4 l68 -24 9 21 c4 12 22 57
38 100 l31 78 -27 10 c-15 5 -38 14 -52 19 -49 19 -156 40 -200 39 -25 0 -69
-7 -98 -15z'
        />
        <path
          d='M710 805 l0 -405 269 0 c236 0 270 2 275 16 8 20 8 152 0 182 -6 21
-10 22 -130 22 l-124 0 0 45 0 45 110 0 110 0 0 106 0 105 -110 -3 -111 -3 3
40 3 40 123 3 122 3 0 76 c0 42 2 74 5 71 3 -2 63 -171 134 -374 l129 -369
128 0 128 0 7 30 c16 76 81 342 86 355 7 20 12 3 63 -213 l42 -178 126 3 127
3 21 65 c12 36 74 217 137 403 l115 337 -147 0 -147 0 -42 -177 c-24 -98 -45
-195 -49 -215 -3 -21 -9 -38 -12 -38 -4 0 -31 97 -60 215 l-53 216 -115 -3
-116 -3 -51 -207 c-28 -114 -54 -209 -57 -212 -3 -3 -27 91 -54 209 l-49 215
-418 0 -418 0 0 -405z'
        />
        <path
          d='M463 361 c-47 -22 -73 -62 -73 -115 0 -44 27 -71 89 -89 24 -7 46
-17 48 -24 7 -20 -34 -25 -72 -8 -21 8 -41 15 -45 15 -4 0 -13 -21 -19 -47
-14 -54 -11 -57 62 -73 64 -14 75 -13 124 9 76 35 98 127 44 182 -16 16 -40
29 -53 29 -13 0 -33 6 -44 14 -18 14 -18 15 -1 22 10 3 35 4 56 0 37 -6 38 -5
50 35 7 23 11 42 9 43 -2 2 -18 8 -37 15 -48 16 -90 13 -138 -8z'
        />
        <path
          d='M767 359 c-89 -47 -121 -172 -65 -256 57 -86 178 -108 256 -46 138
110 65 324 -110 323 -24 -1 -60 -10 -81 -21z m143 -133 c22 -38 -2 -80 -49
-84 -81 -8 -99 95 -21 120 24 8 54 -7 70 -36z'
        />
        <path
          d='M1165 365 c-131 -47 -151 -231 -34 -318 30 -22 101 -33 153 -23 l36
7 0 60 c0 56 -1 59 -19 49 -27 -14 -69 4 -81 35 -20 52 24 97 80 82 18 -5 20
0 20 48 0 30 -3 56 -7 58 -24 14 -112 15 -148 2z'
        />
        <path
          d='M1453 359 c-131 -65 -130 -255 2 -319 71 -34 144 -24 198 28 42 39
57 75 57 132 0 136 -134 220 -257 159z m137 -131 c13 -25 13 -31 0 -55 -30
-55 -120 -35 -120 25 0 64 88 86 120 30z'
        />
        <path
          d='M2210 205 l0 -175 115 0 115 0 0 45 0 45 -55 0 c-48 0 -55 2 -55 20
0 17 7 20 50 20 l50 0 0 39 0 40 -47 3 c-36 2 -49 7 -51 21 -3 14 5 17 52 17
l56 0 0 50 0 50 -115 0 -115 0 0 -175z'
        />
        <path
          d='M1776 358 c-5 -16 -46 -299 -46 -316 0 -8 18 -12 54 -12 52 0 54 1
60 31 3 17 6 48 7 68 1 80 14 76 39 -13 l25 -87 39 3 39 3 27 85 c26 82 27 84
33 50 4 -19 8 -57 9 -85 l3 -50 58 -3 c31 -2 57 1 57 5 0 4 -10 81 -22 171
l-22 162 -66 0 -67 0 -24 -82 -25 -83 -23 83 -24 82 -64 0 c-42 0 -65 -4 -67
-12z'
        />
        <path
          d='M50 231 c0 -116 2 -131 23 -162 34 -51 80 -71 151 -65 117 9 146 56
146 234 l0 122 -65 0 -65 0 0 -118 c0 -78 -4 -122 -12 -130 -16 -16 -23 -15
-42 4 -13 12 -16 39 -16 130 l0 114 -60 0 -60 0 0 -129z'
        />
      </g>
    </Svg>
  );
};
SewUsocome.displayName = 'SewUsocome';

export default SewUsocome;
