import img_1 from './img_1.jpeg'
import img_2 from './img_2.jpeg'
import img_3 from './img_3.jpeg'
import img_4 from './img_4.jpeg'
import img_5 from './img_5.jpeg'
import img_6 from './img_6.jpeg'

export const CATALOGUE_IMG = [
  { name: 'img_1', path: img_1 },
  { name: 'img_2', path: img_2 },
  { name: 'img_3', path: img_3 },
  { name: 'img_4', path: img_4 },
  { name: 'img_5', path: img_5 },
  { name: 'img_6', path: img_6 },
]
