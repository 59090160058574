import React from 'react';
import { Svg } from '../../../Icons/Svg';

export const Edf = ({ primaryColor = '#BB0707', ...props }) => {
  return (
    <Svg optimizationId='iconEdf' viewBox='126.245 53.187 47.027 20' {...props}>
      <g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#FF5E11'
          d='M138.305,56.251c0.075-0.625-0.018-1.537,0.476-1.967
			c0.144-0.666,1.192-0.511,1.029-0.935c-0.101-0.266-0.595-0.139-0.794-0.091c-0.15,0.037-1.589,0.491-2.142,0.554
			c-0.234-0.065-0.468-0.131-0.702-0.196c-0.902,0.121-1.423,0.84-1.925,1.41c-0.422,0.479-0.991,1.648-0.538,2.35
			c-0.024,0.153-0.05,0.306-0.075,0.46l1.025,1.498c0.354,0.254,0.4,0.442,0.781,0.692c0.078,0.118,0.156,0.235,0.235,0.354
			c0.312,0.204,1.229,0.207,1.385,0.074c1.478,0.138,1.317-2.855,1.786-3.483C138.788,56.689,138.54,56.396,138.305,56.251z
			 M131.934,65.237c0.313-0.081,0.848-0.123,1.128-0.263c0.446-0.224,1.98-1.296,1.492-2.169c-0.151-1.84-2.371-0.285-3.316-0.93
			c-0.956-0.123-2.031-1.933-2.319-2.707c-0.112-0.308-0.065-0.202-0.219-0.445c0.001-0.024-0.098,0.085-0.097,0.059
			c-0.28,0.326-0.231,0.61-0.275,1.404c-0.125-0.008-0.25-0.016-0.376-0.023c-0.024,0.288-0.053,0.445-0.188,0.593
			c-0.026,0.049-0.056,0.096-0.083,0.145c-0.237-0.079-0.119,0.034-0.216-0.165c-0.881,0.044-0.815,0.929-1.22,1.433
			C126.262,63.82,129.534,65.859,131.934,65.237z M142.798,60.218c-1.427,0.216-2.3,0.671-2.839,1.044
			c-0.376,0.263-0.486,0.439-0.567,0.549c-0.081,0.111-0.137,0.355-0.096,0.613c0.041,0.256,0.145,0.528,0.367,0.68
			c1.753-0.13,4.795-0.24,5.388,1.232c0.031,0.204,0.077,0.327,0.082,0.376c0.58-0.143,0.155-0.092,1.01,0.043
			c0.196,0.03,0.679-0.078,0.999-0.157c0.146-0.033,0.228-0.166,0.228-0.166s-0.027-0.14-0.02-0.216c0,0,0.3,0.021,0.423-0.072
			c0.811-0.132,0.384-0.513,0.768-0.941C148.31,60.451,145.487,59.811,142.798,60.218z M139.67,66.205
			c-0.406,0.31-0.577,0.233-0.816,0.714c-0.023,0,0.037,0.073,0.012,0.073c0.243,0.483,0.798,0.679,1.207,1.019
			c0.128,0.107,0.473,0.705,0.636,0.748c0.184,0.22,0.304,0.826,0.424,0.826c0.125,0.171-0.01,0.573,0.111,0.729
			c0.01,0.72-0.091,0.771-0.216,0.908c0,0.051,0.069,0.182,0.069,0.23c0.033,0.034,0.322,0.241,0.447,0.216
			c0.392,0.012,1.997,1.265,2.125,1.506c1.151,0.087,1.067-0.265,1.862-0.603c0.215-0.092,0.386-0.005,0.526-0.151
			c0.147-0.15,0.16-0.448,0.302-0.603C146.378,68.986,142.525,66.302,139.67,66.205z M135.133,66.851
			c-0.16,0.171-0.601,0.234-0.724,0.428c-0.188,0.296-0.621,0.969-0.96,1.289c-1.091,1.035-3.425,1.556-4.577,0.566
			c-0.075,0.005-0.031,0.048,0.059,0.331c0.084,0.536,0.516,1.794,0.356,2.115c0.104,0.356,0.212,0.227,0.407,0.424
			c0.134,0.134,0.188,0.483,0.342,0.578c0.176-0.012,0.352-0.023,0.527-0.036c0.031,0.098,0.063,0.195,0.096,0.296
			c0.225-0.018,0.45-0.033,0.676-0.05c0.127,0.028,0.51,0.227,0.622,0.257c0.25-0.018,0.501-0.034,0.751-0.052
			c0.572,0.152,1.072-0.087,1.556-0.413c0.27-0.182,0.564-0.152,0.804-0.357c0.348-0.298,0.819-0.879,1.04-1.28
			c0.543-0.986,0.359-3.347-0.133-3.931C135.784,66.806,135.556,66.802,135.133,66.851z'
        />
      </g>
      <g>
        <path
          fill='#07387A'
          d='M162.778,60.414c2.371,0,3.977,1.498,3.977,4.181c0,3.37-2.203,4.367-4.512,4.367h-3.09
			c0.01-0.187,0-0.685,0-1.311v-5.928c0-0.624,0.01-1.022,0-1.31H162.778z M162.085,67.338c1.311,0,2.375-0.433,2.375-2.744
			c0-1.967-1.064-2.621-2.375-2.621h-0.686v5.365H162.085z'
        />
        <path
          fill='#07387A'
          d='M170.114,67.651c0,0.626,0.029,1.311,0.029,1.311h-2.291c0,0,0.016-0.685,0.016-1.311v-5.928
			c0-0.624-0.016-1.31-0.016-1.31h5.418v1.654c0,0-1.211-0.031-1.621-0.031h-1.535v1.722l1.535,0.017
			c0.287,0,1.275-0.017,1.275-0.017v1.574c0,0-0.877-0.023-1.275-0.023l-1.535,0.01V67.651z'
        />
        <path
          fill='#07387A'
          d='M154.579,67.234c-1.079,0-1.908-0.726-1.908-1.859v-0.096c0,0,2.003-0.05,2.682-0.05
			c1.156,0,1.631-0.011,2.542-0.022c0-0.171,0-0.646,0-0.646c0-2.913-1.236-4.33-3.779-4.33c-1.173,0-3.9,0.44-3.9,4.52
			c0,2.721,1.374,4.347,3.775,4.347c1.886,0,3.043-0.48,3.882-1.093c-0.094-0.142-0.797-1.403-0.889-1.541
			C156.479,66.848,155.649,67.234,154.579,67.234z M152.67,63.644c0.033-0.495,0.211-1.644,1.459-1.644
			c1.28,0,1.351,1.08,1.351,1.649v0.115c0,0-0.854,0.023-1.365,0.023s-1.452-0.023-1.452-0.023L152.67,63.644z'
        />
      </g>
    </Svg>
  );
};
Edf.displayName = 'Edf';

export default Edf;
