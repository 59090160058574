import React from 'react';
import { Svg } from '../../../Icons/Svg';

export const Eiffage = ({ primaryColor = '#F60301', ...props }) => {
  return (
    <Svg optimizationId='iconEiffage' viewBox='0 0 400 126' {...props}>
      <g
        transform='translate(0.000000,176.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path
          fill={primaryColor}
          d='M429 1513 l-44 -48 225 -5 225 -5 3 -217 c1 -120 5 -218 7 -218 16 0
86 90 91 117 3 17 3 118 0 225 l-6 193 -229 3 -228 2 -44 -47z'
        />
        <path
          d='M3085 1391 c-91 -24 -162 -87 -190 -170 -39 -115 0 -227 95 -268 77
-33 255 -13 265 31 10 42 22 146 17 151 -3 3 -29 5 -57 5 l-52 0 -12 -75 -12
-75 -40 0 c-66 0 -99 49 -99 148 0 147 112 245 224 198 49 -20 53 -20 59 4 4
14 -2 25 -17 36 -27 17 -138 27 -181 15z'
        />
        <path
          fill={primaryColor}
          d='M320 1340 l0 -50 220 0 220 0 0 50 0 50 -220 0 -220 0 0 -50z'
        />
        <path
          d='M1225 1378 c-11 -32 -61 -424 -55 -431 4 -4 63 -6 131 -5 116 3 124
4 124 23 0 18 -8 20 -68 23 l-68 3 7 72 c4 40 9 75 11 80 2 4 30 7 62 7 39 0
63 5 71 15 20 24 2 35 -61 35 -66 0 -65 -3 -51 90 l7 45 70 5 c63 5 70 7 73
28 3 22 2 22 -123 22 -91 0 -127 -3 -130 -12z'
        />
        <path
          d='M1586 1363 c-4 -16 -13 -77 -21 -138 -8 -60 -19 -139 -25 -175 -17
-113 -18 -110 45 -110 65 0 55 -23 90 222 35 244 37 228 -29 228 -51 0 -55 -2
-60 -27z'
        />
        <path
          d='M1826 1363 c-9 -45 -56 -380 -56 -402 0 -19 6 -21 55 -21 49 0 55 3
60 23 4 19 25 171 25 183 0 2 26 4 58 4 64 0 88 10 79 34 -5 12 -21 16 -67 16
l-61 0 6 48 c13 98 7 92 84 92 54 0 70 3 75 16 3 9 4 20 0 25 -3 5 -61 9 -129
9 l-123 0 -6 -27z'
        />
        <path
          d='M2186 1382 c-3 -5 -12 -62 -21 -128 -9 -65 -22 -161 -29 -211 -7 -51
-11 -95 -8 -98 9 -8 100 -6 106 3 3 5 10 42 16 83 20 130 13 119 79 119 39 0
63 5 71 15 20 24 2 35 -61 35 -58 0 -59 1 -58 28 0 15 3 45 7 67 l7 40 70 5
c63 5 70 7 73 28 3 22 2 22 -122 22 -69 0 -127 -4 -130 -8z'
        />
        <path
          d='M2522 1175 c-67 -119 -122 -221 -122 -226 0 -6 12 -9 27 -7 21 2 35
17 67 70 42 70 54 78 123 78 52 0 69 -16 78 -76 10 -72 12 -74 65 -74 27 0 51
3 53 8 2 4 -16 104 -42 222 l-46 215 -41 3 -40 3 -122 -216z m127 48 c25 -101
27 -93 -29 -93 -27 0 -50 4 -50 9 0 9 62 121 67 121 1 0 6 -17 12 -37z'
        />
        <path
          d='M3400 1376 c0 -7 -13 -105 -30 -216 -16 -112 -27 -207 -24 -211 3 -5
61 -9 130 -9 l124 0 0 25 c0 24 -2 25 -65 25 l-65 0 1 38 c0 20 3 55 7 77 l7
40 65 5 c58 4 65 8 68 27 3 20 -1 22 -60 25 -70 3 -66 -4 -53 90 l6 47 72 3
c66 3 72 5 75 26 3 22 3 22 -127 22 -105 0 -131 -3 -131 -14z'
        />
        <path
          fill={primaryColor}
          d='M327 1214 c-4 -4 -7 -27 -7 -51 l0 -43 221 0 220 0 -3 48 -3 47 -211
3 c-115 1 -213 -1 -217 -4z'
        />
        <path
          fill={primaryColor}
          d='M322 993 l3 -48 215 0 215 0 3 48 3 47 -221 0 -221 0 3 -47z'
        />
        <path d='M1235 759 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z' />
        <path
          d='M2812 759 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z'
        />
        <path
          d='M1180 655 l0 -75 50 0 c28 0 50 5 50 10 0 6 -16 10 -35 10 -28 0 -35
4 -35 20 0 16 7 20 30 20 20 0 30 5 30 15 0 10 -10 15 -30 15 -42 0 -40 28 2
32 55 5 46 28 -11 28 l-51 0 0 -75z'
        />
        <path
          d='M1320 654 c0 -56 3 -75 13 -72 6 3 14 27 17 54 l5 49 33 -53 c22 -36
38 -52 50 -50 14 3 17 15 17 72 0 45 -4 71 -12 73 -8 3 -13 -13 -15 -49 l-3
-53 -33 53 c-22 33 -41 52 -53 52 -17 0 -19 -8 -19 -76z'
        />
        <path
          d='M1500 655 l0 -75 50 0 c28 0 50 5 50 10 0 6 -16 10 -35 10 -28 0 -35
4 -35 19 0 15 8 21 33 23 17 2 32 8 32 13 0 6 -15 11 -32 13 -43 4 -44 30 -1
34 55 5 46 28 -11 28 l-51 0 0 -75z'
        />
        <path
          d='M1640 655 c0 -60 3 -75 15 -75 10 0 15 10 15 30 0 17 4 30 10 30 5 0
16 -13 25 -30 9 -16 22 -30 30 -30 19 0 19 5 -1 35 -15 22 -15 26 0 40 38 38
9 75 -58 75 l-36 0 0 -75z m80 29 c0 -8 -10 -14 -25 -14 -23 0 -34 18 -19 32
10 10 44 -4 44 -18z'
        />
        <path
          d='M1802 710 c-63 -59 9 -162 91 -131 22 9 27 17 27 46 0 38 -17 52 -47
40 -17 -6 -17 -7 0 -19 27 -21 22 -46 -11 -46 -35 0 -52 16 -52 51 0 41 26 62
68 54 26 -5 33 -3 30 7 -8 23 -80 22 -106 -2z'
        />
        <path
          d='M1960 655 c0 -60 3 -75 15 -75 12 0 15 15 15 75 0 60 -3 75 -15 75
-12 0 -15 -15 -15 -75z'
        />
        <path
          d='M2037 723 c-4 -3 -7 -37 -7 -75 l0 -68 50 0 c28 0 50 5 50 10 0 6
-16 10 -35 10 -28 0 -35 4 -35 19 0 15 8 21 33 23 17 2 32 8 32 13 0 6 -15 11
-32 13 -43 4 -44 30 -1 34 51 5 47 28 -4 28 -25 0 -48 -3 -51 -7z'
        />
        <path
          d='M2252 718 c-22 -22 -13 -48 23 -69 45 -27 48 -54 5 -46 -37 8 -49 -9
-16 -24 20 -10 30 -9 51 5 40 26 33 61 -18 84 -35 15 -30 38 7 35 17 -1 26 3
26 13 0 18 -61 19 -78 2z'
        />
        <path
          d='M2370 723 c0 -5 12 -26 25 -48 14 -22 25 -52 25 -67 0 -16 5 -28 10
-28 6 0 10 12 10 27 0 14 12 46 26 70 31 53 23 71 -12 28 -24 -29 -25 -29 -39
-11 -8 11 -15 23 -15 28 0 4 -7 8 -15 8 -8 0 -15 -3 -15 -7z'
        />
        <path
          d='M2532 718 c-22 -22 -13 -48 23 -70 40 -23 41 -43 2 -43 -37 0 -44
-12 -14 -26 22 -10 31 -9 52 5 36 23 32 53 -10 78 -40 23 -41 44 -1 41 17 -1
26 3 26 13 0 18 -61 19 -78 2z'
        />
        <path
          d='M2642 718 c3 -7 14 -14 26 -16 20 -3 22 -9 22 -64 0 -79 24 -76 28 4
2 46 7 57 22 61 43 11 19 27 -41 27 -44 0 -60 -3 -57 -12z'
        />
        <path
          d='M2790 655 l0 -75 50 0 c55 0 69 17 18 22 -47 4 -51 35 -6 40 42 4 43
22 1 26 -43 4 -44 30 -1 34 55 5 46 28 -11 28 l-51 0 0 -75z'
        />
        <path
          d='M2937 723 c-4 -3 -7 -37 -7 -75 0 -83 22 -91 30 -12 l5 49 20 -52
c11 -29 23 -53 28 -53 4 0 18 24 31 53 l24 52 1 -53 c1 -38 5 -52 14 -50 7 3
13 29 15 71 2 55 0 68 -13 73 -20 8 -37 -8 -51 -48 -11 -34 -34 -50 -34 -23 0
43 -42 89 -63 68z'
        />
        <path
          d='M3140 655 l0 -75 50 0 c28 0 50 5 50 10 0 6 -16 10 -35 10 -28 0 -35
4 -35 20 0 16 7 20 30 20 20 0 30 5 30 15 0 10 -10 15 -30 15 -42 0 -40 28 2
32 55 5 46 28 -11 28 l-51 0 0 -75z'
        />
        <path
          d='M3286 711 c-22 -24 -8 -58 29 -67 16 -4 25 -13 25 -26 0 -17 -5 -20
-30 -14 -35 7 -44 -18 -9 -27 63 -17 100 63 39 86 -16 6 -32 17 -34 24 -5 13
0 15 36 17 31 2 24 21 -8 24 -20 2 -36 -4 -48 -17z'
        />
      </g>
    </Svg>
  );
};
Eiffage.displayName = 'Eiffage';

export default Eiffage;
